import React, { useEffect } from "react";
import axios from "axios";
import "./App.css";
import imaglogo from "./images/logo.png";
import imaglogo1 from "./images/cactus.png";
import logoPeriodico from "./images/periodico.png";
import logoTele from "./images/oldtv.png";
import logoValla from "./images/billboard.png";
import logoDesktop from "./images/desktop.png";
import logoLaptop from "./images/laptop.png";
import logoCellphone from "./images/cellphone.png";
import logoTablet from "./images/ipad.png";
import logoGoogle from "./images/google.png";
import logoBebe from "./images/bebemundo.png";
import logoCemaco from "./images/cemaco.png";
import logoRadioShack from "./images/radioShack.png";
import logoAmazon from "./images/amazon.png";
import logoBokitas from "./images/bokitas.png";
import logoTosh from "./images/tosh.png";
import logoCinde from "./images/cinde.png";
import logoMarViva from "./images/MarViva.png";
import logoHeineken from "./images/heineken.png";
import logoHusqvarna from "./images/husqvarna.png";
import logoJugueton from "./images/jugueton.png";
import logoViva from "./images/viva.png";
import Grid from "@mui/material/Grid";
import Webcam from "react-webcam";
import { Button, Container } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { maxHeight } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";

function App() {
  const webcamRef = React.useRef(null);
  const [imageSrc, setImageSrc] = React.useState(imaglogo);
  const [nombreEmail, setNombreEmail] = React.useState();
  const [emailEmail, setEmailEmail] = React.useState();
  const [nombrePais, setNombrePais] = React.useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("response")) {
      let response = params.get("response");
      alert(response);
    }
  }, []);

  const capture = React.useCallback(() => {
    webcamRef.current === undefined && window.location.reload();
    setImageSrc(webcamRef.current.getScreenshot());
    fetch("./mail.php", {
      data: { email: emailEmail, brandName: nombreEmail, country: nombrePais },
    });
  }, [webcamRef, emailEmail, nombreEmail, nombrePais]);

  return (
    <>
      <Grid container style={{ overflow: "hidden" }}>
        <Hidden smDown>
          <Grid item style={{ overflow: "hidden" }} container>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              backgroundColor="white"
            >
              <div class="icon">
              <img
                src={imaglogo}
                alt="logo"
                style={{
                  position: "relative",
                  width: "80%",
                  left: 30,
                  top:3,
                }}
              />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{
                position: "relative",
                bottom: "5%",
                textAlign: "center",
              }}
            >
              <div className="titulo" style={{ position: "relative" }}>
                PONEMOS SU IMAGEN EN EL MEDIO QUE NECESITE ESTAR:
              </div>

              <div
                className="titulo1"
                style={{ position: "relative", wordSpacing: "5px" }}
              >
                (haga la prueba gratis si no nos cree)
              </div>

              <div
                className="container1"
                style={{ position: "relative", top: 30, textAlign: "center" }}
              >
                <form
                  id="main-contact-form"
                  class="contact-form"
                  name="contact-form"
                  method="post"
                  action="mail.php"
                >
                  <div class="col-sm-5 col-sm-offset-1">
                    <div
                      class="form-group"
                      style={{
                        fontFamily: "helvetica",
                        padding: 5,
                        fontWeight: "bold",
                      }}
                    >
                      <label>email: </label>
                      <input
                        type="text"
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: "26px",
                        }}
                        name="email"
                        class="form-control"
                        required="required"
                      />
                    </div>

                    <div
                      class="form-group"
                      style={{
                        padding: 5,
                        fontFamily: "helvetica",
                        fontWeight: "bold",
                      }}
                    >
                      <label>nombre de su marca: </label>
                      <input
                        type="text"
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: "26px",
                        }}
                        name="brandName"
                        class="form-control"
                      />
                    </div>

                    <div
                      class="form-group"
                      style={{
                        padding: 5,
                        fontFamily: "helvetica",
                        fontWeight: "bold",
                      }}
                    >
                      <label>país: </label>
                      <input
                        type="country"
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: "26px",
                        }}
                        name="country"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group">
                      <Button
                        variant="outlined"
                        type="submit"
                        name="submit"
                        class="btn btn-primary btn-lg"
                        required="required"
                        style={{
                          backGround: "white",
                          color: "black",
                          border: "3px solid black",
                          fontSize: "24px",
                          padding: 7,
                          position: "relative",
                          top: 20,
                        }}
                      >
                        CONTACT US
                      </Button>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <img
                src={imaglogo1}
                alt="logo"
                style={{
                  width: "170%",
                  position: "relative",
                  right: "18%",
                  bottom: "5%",
                }}
              />
            </Grid>{" "}
          </Grid>

          <Grid item container style={{ overflow: "hidden" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              textAlign="center"
              position="relative"
              style={{
                display: imaglogo !== imageSrc ? "none" : "",
                bottom: 90,
              }}
            >
              <Webcam style={{ width: "40vw" }} ref={webcamRef} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              textAlign="center"
              position="relative"
              style={{ bottom: 80 }}
            >
              <Button
                variant="outlined"
                onClick={capture}
                style={{
                  backGround: "white",
                  color: "black",
                  border: "3px solid black",
                  fontSize: "25px",
                  padding: 5,
                }}
              >
                PHOTOBOOTH
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              textAlign="center"
              position="relative"
              style={{ bottom: 80 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setImageSrc(imaglogo);
                }}
                style={{
                  backGround: "white",
                  color: "black",
                  border: "3px solid black",
                  fontSize: "25px",
                  padding: 5,
                  marginTop: "7px",
                }}
              >
                {" "}
                ¡Tómela otra vez!{" "}
              </Button>
            </Grid>{" "}
          </Grid>

          <Grid container backgroundColor="#2AAA8A" style={{paddingTop:"10px"}}>
            
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="paginaUno"
                style={{
                  position: "relative",
                  textAlign: "right",
                  right: "2%",
                  top: "30%",
                  zIndex: "100",
                }}
              >
                ATL EN GENERAL
              </div>{" "}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="paginaUnoD"
                style={{
                  position: "relative",
                  textAlign: "right",
                  right: "2%",
                  top: "30%",
                  zIndex: "100",
                }}
              >
                EXTERIORES, PRENSA, RADIO, <br />
                TELE, YOU NAME IT...
              </div>
            </Grid>{" "}
         

          
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "20% 25%",
                  backgroundSize: "100% 74%",
                  zIndex: "1",
                  left: "10%",
                }}
              >
                <img
                  src={logoValla}
                  alt="¡Tómela otra vez!"
                  style={{ maxWidth: "100%", position: "relative" }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  backgroundSize: "78% 40%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "90% 80%",
                  position: "relative",
                  top: "20%",
                  right: "15%",
                  zIndex: "2",
                }}
              >
                <img
                  src={logoPeriodico}
                  alt="¡Tómela otra vez!"
                  style={{
                    maxWidth: "130%",
                    position: "relative",
                    bottom: "10%",
                    overflow: "hidden",
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "65% 70%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "41% 42%",
                  top: "55%",
                  right: "20%",
                  zIndex: "1",
                }}
              >
                <img
                  src={logoTele}
                  alt="¡Tómela otra vez!"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            container
            backgroundColor="#D61987"
            style={{ top: "200px", paddingBottom:"100px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="paginaDos"
                style={{ position: "relative", left: 30,paddingTop:"30px" }}
              >
                (DIGITAL)
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="paginaDosP"
                style={{ position: "relative", left: 30, paddingTop:"30px" }}
              >
                (REDES SOCIALES, GOOGLE adwords, SEO, programática, Augmented
                Reality, etc,)
              </div>
            </Grid>
     
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "65% 76%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "35% 46%",
                  left: "35%",
                  top: "64%",
                  zIndex: "1",
                  borderRadius: "25px",
                }}
              >
                <img
                  style={{ maxWidth: "90%", position: "relative" }}
                  src={logoTablet}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "85% 60%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "140% 33%",
                  top: "25%",
                  display:"flex",
                  right:"30%"

                }}
              >
                <img
                  style={{
                    width: "130%",
                    position: "relative",
                    right:"4%"
                    
                  }}
                  src={logoDesktop}
                  alt="¡Tómela otra vez!"
                />
              </div>
          
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "52% 65%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "56% 37%",
                  bottom: "10%",
                  left: "50%",
                }}
              >
                <img
                  style={{
                    maxWidth: "70%",
                    position: "relative",
                    left: "19%",
                  }}
                  src={logoLaptop}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ overflow: "hidden" }}
            >
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "20% 40%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "30% 49%",
                  top: "66%",
                  left: "48%",
                  zIndex: "1",
                }}
              >
                <img
                  src={logoCellphone}
                  style={{ position: "relative", left:"18%" }}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            container
            backgroundColor="#fff176"
            
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ position: "relative", paddingLeft: 30, paddingTop: 30 }}
            >
              <div className="paginaTres">
                SOMOS PROACTIVOS, NOS GUSTA LA INNOVACIÓN, CUBRIMOS LA REGIÓN, Y
                COBRAMOS LO JUSTO...
              </div>

              <div className="subtituloTres">
                SI NO NOS CREE PREGÚNTELE A ELLOS:
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ position: "relative", paddingTop: "15%", left:"40px" }}
            >
              <div>
                <img
                  style={{ maxWidth: "55%", position: "relative", display:"flex", textAlign:"center"}}
                  src={logoBebe}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{paddingTop:"20px"}}>
              <div>
                <img
                  style={{ maxWidth: "60%", position: "relative", display:"flex", textAlign:"center" }}
                  src={logoRadioShack}
                  alt="¡Tómela otra vez!"
                />
                <img
                  style={{ maxWidth: "55%", position: "relative", display:"flex", textAlign:"center" }}
                  src={logoAmazon}
                  alt="¡Tómela otra vez!"
                />

                <img
                  style={{ maxWidth: "50%", position: "relative",  display:"flex", textAlign:"center" }}
                  src={logoBokitas}
                  alt="¡Tómela otra vez!"
                />

                <img
                  style={{ maxWidth: "54%", position: "relative",  display:"flex", textAlign:"center" }}
                  src={logoTosh}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ position: "relative", paddingTop: 34 }}
            >
              <div>
                <img
                  style={{ maxWidth: "60%", position: "relative", display:"flex", textAlign:"center" }}
                  src={logoCinde}
                  alt="¡Tómela otra vez!"
                />

                <img
                  style={{ maxWidth: "70%", display:"flex", textAlign:"center" }}
                  src={logoMarViva}
                  alt="¡Tómela otra vez!"
                />
                <img
                  style={{ maxWidth: "70%",  display:"flex", textAlign:"center" }}
                  src={logoHeineken}
                  alt="¡Tómela otra vez!"
                />

                <img
                  style={{ maxWidth: "70%",  display:"flex", textAlign:"center" }}
                  src={logoHusqvarna}
                  alt="¡Tómela otra vez!"
                />

                <img
                  style={{ maxWidth: "60%" }}
                  src={logoViva}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{padding:"1px", position:"relative"}}className="App-credits"><h3 style={{top:"26px", position:"relative"}}>Comma Pump Development®</h3></div>
          </Grid>
        </Hidden>
      </Grid>

{/* MOBILE PRESENTATION */}
{/* MOBILE PRESENTATION */}
{/* MOBILE PRESENTATION */}
{/* MOBILE PRESENTATION */}
{/* MOBILE PRESENTATION */}
{/* MOBILE PRESENTATION */}


      <Grid container style={{ overflow: "hidden" }}>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            backgroundColor="white"
          >
            <img
              src={imaglogo}
              alt="logo"
              style={{
                position: "relative",
                width: "30%",
                top: "20px",
                left: "3%",
              }}
            />

            <img
              src={logoGoogle}
              alt="logo"
              style={{
                position: "relative",
                width: "30%",
                top: "-50%",
                left: "40%",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{
              position: "relative",

              bottom: "180px",
            }}
          >
            <div
              className="tituloCel"
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              PONEMOS SU IMAGEN EN EL MEDIO QUE NECESITE ESTAR:
            </div>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: 10 }}
              container
              backgroundColor="white"
              direction="column"
              alignItems="center"
              justifyContent="justify"
            >
              <div className="titulo1Cel" style={{ padding: 10 }}>
                (haga la prueba gratis si no nos cree)
              </div>

              <div
                className="container1Cel"
                style={{
                  position: "relative",
                  top: "30%",
                  textAlign: "center",
                }}
              >
                <form
                  id="main-contact-form"
                  class="contact-form"
                  name="contact-form"
                  method="post"
                  action="mail.php"
                >
                  <div class="col-sm-5 col-sm-offset-1">
                    <div class="form-group">
                      <label>email: </label>
                      <input
                        type="text"
                        style={{ backgroundColor: "black", color: "white" }}
                        name="email"
                        class="form-control"
                        required="required"
                      />
                    </div>

                    <div class="form-group">
                      <label>nombre de su marca: </label>
                      <input
                        type="text"
                        style={{ backgroundColor: "black", color: "white" }}
                        name="brandName"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group">
                      <label>país: </label>
                      <input
                        type="text"
                        style={{ backgroundColor: "black", color: "white" }}
                        name="country"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary btn-lg"
                        required="required"
                        style={{
                          backGround: "white",
                          color: "black",
                          border: "3px solid black",
                          fontSize: "15px",
                          padding: 7,
                          position: "relative",
                          top: "10px",
                        }}
                      >
                        CONTACT US
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <img
              src={imaglogo1}
              alt="logo"
              style={{ width: "100%", position: "relative", bottom: "140px" }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            textAlign="center"
            position="relative"
            style={{ bottom: 180 }}
          >
            <Button
              variant="outlined"
              onClick={capture}
              style={{
                backGround: "white",
                color: "black",
                border: "3px solid black",
                fontSize: "14px",
                padding: 7,
                position: "relative",
              }}
            >
              Photo Booth
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            textAlign="center"
            position="relative"
            style={{ display: imaglogo !== imageSrc ? "none" : "", bottom: 90 }}
          >
            <Webcam style={{ width: "40vw" }} ref={webcamRef} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            textAlign="center"
            position="relative"
            style={{ bottom: 80 }}
          >
            <Button
              style={{
                backGround: "white",
                color: "black",
                border: "3px solid black",
                fontSize: "14px",
                padding: 7,
                position: "relative",
              }}
              variant="outlined"
              onClick={() => {
                setImageSrc(imaglogo);
              }}
            >
              {" "}
              ¡Tómela otra vez!{" "}
            </Button>
          </Grid>

          <Grid container backgroundColor="#2AAA8A">
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                className="paginaUno"
                style={{ position: "relative", top: 50, left: 40 }}
              >
                ATL EN GENERAL
              </div>

              <div
                className="paginaUnoY"
                style={{ position: "relative", top: 50, left: 50 }}
              >
                EXTERIORES, PRENSA, RADIO, TELE, YOU NAME IT...
              </div>

              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "90vw 49vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "45% 30%",
                  top: 70,

                  zIndex: "1",
                }}
              >
                <img
                  src={logoValla}
                  alt="¡Tómela otra vez!"
                  style={{ maxWidth: "80%", position: "relative", left: 30 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  backgroundSize: "57vw 19vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "38% 80%",
                  position: "relative",
                  top: 65,
                  right: 40,
                  zIndex: "2",
                }}
              >
                <img
                  src={logoPeriodico}
                  alt="¡Tómela otra vez!"
                  style={{ maxWidth: "100%", position: "relative" }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "50vw 21vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "19% 33%",
                  top: 40,
                  left: 80,
                  zIndex: "2",
                }}
              >
                <img
                  src={logoTele}
                  alt="¡Tómela otra vez!"
                  style={{ maxWidth: "80%" }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container backgroundColor="#D61987">
            <div
              className="paginaDos"
              style={{ position: "relative", top: 60, left: 30 }}
            >
              (DIGITAL)
            </div>

            <div
              className="paginaDosY"
              style={{ position: "relative", top: 60, left: 30 }}
            >
              (REDES SOCIALES, GOOGLE adwords, SEO, programática, Augmented
              Reality, etc,)
            </div>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "32vw 11.3vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "8% 46%",
                  top: 360,
                  right: 16,
                  zIndex: 1,
                  borderRadius: "25px",
                }}
              >
                <img
                  style={{
                    maxWidth: "43%",
                    borderRadius: "25px",
                    position: "relative",
                  }}
                  src={logoTablet}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "75vw 24vh",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "86% 28%",

                  right: 30,
                }}
              >
                <img
                  style={{ maxWidth: "120%", position: "relative" }}
                  src={logoDesktop}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "36% 70%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "11% 39%",
                  left: 200,
                  bottom: 130,
                }}
              >
                <img
                  style={{ maxWidth: "50%" }}
                  src={logoLaptop}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <div
                style={{
                  backgroundImage: `url(${imageSrc})`,
                  position: "relative",
                  backgroundSize: "17% 48%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "6.5% 50%",
                  bottom: 190,
                  left: 120,
                }}
              >
                <img
                  style={{ maxWidth: "30%" }}
                  src={logoCellphone}
                  alt="¡Tómela otra vez!"
                />
              </div>
            </Grid>
          </Grid>

          <Grid container backgroundColor="#fff176">
            <Grid item xs={12} style={{ position: "relative", top: 10,  display:"grid", textAlign:"center"}}>
              <div className="paginaTres">
                SOMOS PROACTIVOS, NOS GUSTA LA INNOVACIÓN, CUBRIMOS LA REGIÓN, Y
                COBRAMOS LO JUSTO...
              </div>

              <div className="subtituloTres">
                SI NO NOS CREE PREGÚNTELE A ELLOS:
              </div>
            </Grid>

            <Grid
              container
              backgroundColor="#fff176"
              style={{ position: "relative", top: 10 }}
            >
              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center" }}>
                <div>
                  <img
                    style={{ maxWidth: "50%", position: "relative" }}
                    src={logoBebe}
                    alt="¡Tómela otra vez!"
                  />{" "}
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative", display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "45%" }}
                    src={logoRadioShack}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "50%" }}
                    src={logoAmazon}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative", display:"flex", textAlign:"center" }}>
                {" "}
                <div>
                  <img
                    style={{ maxWidth: "45%" }}
                    src={logoBokitas}
                    alt="¡Tómela otra vez!"
                  />{" "}
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "50%" }}
                    src={logoTosh}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "45%" }}
                    src={logoCinde}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative", display:"flex", textAlign:"center" }}>
                <div>
                  <img
                    style={{ maxWidth: "50%" }}
                    src={logoMarViva}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "60%" }}
                    src={logoHeineken}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative",  display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "50%" }}
                    src={logoHusqvarna}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>

              <Grid item xs={12} style={{ position: "relative", display:"flex", textAlign:"center"}}>
                <div>
                  <img
                    style={{ maxWidth: "45%" }}
                    src={logoViva}
                    alt="¡Tómela otra vez!"
                  />
                </div>{" "}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{padding:"2px"}} className="App-credits"><h3 style={{top:"26px", position:"relative"}}>Comma Pump Development®</h3></div>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
}

export default App;
